export interface OpacityCssVariables {
    '--opacity-lower': number;
    '--opacity-low': number;
    '--opacity-medium': number;
    '--opacity-high': number;
}

export const opacityCssVariables: OpacityCssVariables = {
    '--opacity-lower': 0.75,
    '--opacity-low': 0.5,
    '--opacity-medium': 0.25,
    '--opacity-high': 0.1,
};
