import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const Pattern = ({ color = 'var(--color-pattern-backdrop)', $style, ...rest }) => {
    return (
        <SVG $style={$style} width="100%" height="100%" {...rest}>
            <defs>
                <pattern id="dots" x="0" y="0" width="18" height="18" patternUnits="userSpaceOnUse">
                    <path fill={color} d="m3 .501953 2.82842712 2.82842712L3 6.15880725.17157288 3.33038012z" />
                </pattern>
            </defs>

            <rect x="0" y="0" width="100%" height="100%" fill="url(#dots)" />
        </SVG>
    );
};

Pattern.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default Pattern;
