import { ButtonsCssVariables } from 'config/branding/interfaces/buttons';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const buttonsCssVariables: ButtonsCssVariables = {
    // ----------------------------//
    // Default
    // ---------------------------//
    '--color-text-button-default': 'var(--color-base-black)',
    '--transition-all-button-default': 'var(--transition-fast)',
    '--color-border-button-default': 'var(--color-base-black)',

    // Default (Hover)
    '--color-bg-button-default-hover': 'var(--color-base-black-25)',
    '--color-text-button-default-hover': 'var(--color-base-black)',
    '--color-border-button-default-hover': 'var(--color-base-black)',

    // Default (Active)
    '--color-bg-button-default-active': 'var(--color-base-black)',
    '--color-text-button-default-active': 'var(--color-base-white)',
    '--color-border-button-default-active': 'var(--color-base-black)',

    // Default (Disabled)
    '--color-bg-button-default-disabled': 'var(--color-base-white)',
    '--color-text-button-default-disabled': 'var(--color-base-brand-grey-dark)',
    '--color-border-button-default-disabled': 'var(--color-base-black)',

    // ----------------------------//
    // Inverted
    // ---------------------------//
    '--color-bg-button-inverted': 'var(--color-base-black-10)',
    '--color-text-button-inverted': 'var(--color-base-white)',
    '--transition-all-button-inverted': 'var(--transition-fast)',
    '--color-border-button-inverted': 'var(--color-base-white)',
    // Inverted (Hover)
    '--color-bg-button-inverted-hover': 'var(--color-base-black-50)',
    '--color-text-button-inverted-hover': 'var(--color-base-white)',
    '--color-border-button-inverted-hover': 'var(--color-base-white)',

    // Inverted (Active)
    '--color-bg-button-inverted-active': 'var(--color-base-white)',
    '--color-text-button-inverted-active': 'var(--color-base-black)',
    '--color-border-button-inverted-active': 'var(--color-base-black)',

    // Inverted (Disabled)
    '--color-bg-button-inverted-disabled': 'var(--color-base-brand-grey-neutral)',
    '--color-text-button-inverted-disabled': 'var(--color-base-white)',

    // ----------------------------//
    // Default - Secondary
    // ---------------------------//
    '--color-bg-button-defaultsecondary': 'var(--color-base-black)',
    '--color-text-button-defaultsecondary': 'var(--color-base-white)',
    '--transition-all-button-defaultsecondary': 'var(--transition-fast)',

    // Default (Hover)
    '--color-bg-button-defaultsecondary-hover': 'var(--color-base-black)',
    '--color-text-button-defaultsecondary-hover': 'var(--color-base-brand-grey-neutral)',

    // Default (Active)
    '--color-bg-button-defaultsecondary-active': 'var(--color-base-white)',
    '--color-text-button-defaultsecondary-active': 'var(--color-base-black)',
    '--color-border-button-defaultsecondary-active': 'var(--color-base-black)',

    // Default (Disabled)
    '--color-bg-button-defaultsecondary-disabled': 'var(--color-base-brand-grey-dark)',
    '--color-text-button-defaultsecondary-disabled': 'var(--color-base-white)',

    // ----------------------------//
    // Inverted - Secondary
    // ---------------------------//
    '--color-bg-button-invertedsecondary': 'var(--color-base-white)',
    '--color-text-button-invertedsecondary': 'var(--color-base-black)',
    '--transition-all-button-invertedsecondary': 'var(--transition-fast)',

    // Inverted - Secondary (Hover)
    '--color-bg-button-invertedsecondary-hover': 'var(--color-base-white)',
    '--color-text-button-invertedsecondary-hover': 'var(--color-base-brand-grey-dark)',

    // Inverted - Secondary (Active)
    '--color-bg-button-invertedsecondary-active': 'var(--color-base-black)',
    '--color-text-button-invertedsecondary-active': 'var(--color-base-white)',
    '--color-border-button-invertedsecondary-active': 'var(--color-base-white)',

    // Inverted - Secondary (Disabled)
    '--color-bg-button-invertedsecondary-disabled': 'var(--color-base-brand-grey-neutral)',
    '--color-text-button-invertedsecondary-disabled': 'var(--color-base-white)',

    // ----------------------------//
    // Links
    // ---------------------------//
    '--color-text-button-link': 'var(--color-base-black)',

    // Link (Hover)
    '--color-bg-button-link-hover': 'var(--color-base-brand-grey-dark)',

    // Link (Active)
    '--color-text-button-link-active': 'var(--color-base-brand-grey-dark)',

    // ----------------------------//
    // Default - Inverted
    // ---------------------------//
    '--color-bg-button-defaultinverted': 'var(--color-base-white)',
    '--color-text-button-defaultinverted': 'var(--color-base-brand-black)',
    '--transition-all-button-defaultinverted': 'var(--transition-fast)',
    '--color-border-button-defaultinverted': 'var(--color-base-brand-grey-light)',

    // Default - Inverted (Hover)
    '--color-bg-button-defaultinverted-hover': 'var(--color-base-brand-grey-light)',
    '--color-text-button-defaultinverted-hover': 'var(--color-base-black)',

    // Default - Inverted (Active)
    '--color-bg-button-defaultinverted-active': 'var(--color-base-white)',
    '--color-text-button-defaultinverted-active': 'var(--color-base-black)',
    '--color-border-button-defaultinverted-active': 'var(--color-base-black)',

    // Default - Inverted (Disabled)
    '--color-bg-button-defaultinverted-disabled': 'var(--color-base-brand-grey)',
    '--color-text-button-defaultinverted-disabled': 'var(--color-base-black)',
};
