import { CheckoutCssVariablesCore } from '@activebrands/core-web/types/view/checkout';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const checkoutCssVariablesCore: CheckoutCssVariablesCore = {
    '--color-bg-checkout-act-content': 'var(--color-base-grey-50)',
    '--color-bg-checkout-act-information': 'var(--color-base-white)',
    '--color-bg-checkout-act-vouchers-active': 'var(--color-base-black)',
    '--color-bg-checkout-act-vouchers': 'var(--color-base-white)',
    '--color-bg-checkout-info-boxes': 'var(---color-base-grey-50)',
    '--color-bg-checkout-summary-products': 'var(--color-base-white)',
    '--color-bg-checkout-summary-total': 'var(--color-base-white)',
    '--color-bg-checkout-summary-vouchers-autofill': 'var(--color-palette-neutral-200)',
    '--color-bg-checkout': 'var(--color-base-white)',
    '--color-border-checkout-act-content': 'var(--color-base-black)',
    '--color-border-checkout-act-vouchers-active': 'var(--color-base-black)',
    '--color-border-checkout-act-vouchers': 'var(--color-base-brand-grey-light)',
    '--color-border-checkout-info-boxes': 'var(--color-base-black)',
    '--color-border-checkout-subtle': 'var(--color-base-brand-grey-light)',
    '--color-icon-checkout-act-content': 'var(--color-palette-act-blue)',
    '--color-text-checkout-act-information': 'var(--color-base-black)',
    '--color-text-checkout-act-vouchers-active': 'var(--color-base-white)',
    '--color-text-checkout-act-vouchers': 'var(--color-base-black)',
    '--color-text-checkout-error': 'var(--color-base-dange)',
    '--color-text-checkout-steps-active': 'var(--color-base-black)',
    '--color-text-checkout-steps': 'var(--color-base-brand-grey-dark)',
    '--color-text-checkout-subtle': 'var(--color-base-brand-grey-dark)',
};
