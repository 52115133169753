import ColorsCssVariables from '@activebrands/core-web/interfaces/colors';
import { CssVariables, HEX, RGB, RGBA } from '@activebrands/core-web/types/css-types';

export interface ColorCssVariables extends CssVariables<RGBA | RGB | HEX> {
    '--color-base-black-10': RGBA | RGB | HEX;
    '--color-base-black-25': RGBA | RGB | HEX;
    '--color-base-black-50': RGBA | RGB | HEX;
    '--color-base-black-75': RGBA | RGB | HEX;
    '--color-base-black': RGBA | RGB | HEX;
    '--color-base-brand-black': RGBA | RGB | HEX;
    '--color-base-brand-grey-dark': RGBA | RGB | HEX;
    '--color-base-brand-grey-light': RGBA | RGB | HEX;
    '--color-base-brand-grey-lighter': RGBA | RGB | HEX;
    '--color-base-brand-grey-neutral': RGBA | RGB | HEX;
    '--color-base-brand-white': RGBA | RGB | HEX;
    '--color-base-error': RGBA | RGB | HEX;
    '--color-base-grey-50': RGBA | RGB | HEX;
    '--color-base-success': RGBA | RGB | HEX;
    '--color-base-white-10': RGBA | RGB | HEX;
    '--color-base-white-25': RGBA | RGB | HEX;
    '--color-base-white-50': RGBA | RGB | HEX;
    '--color-base-white-75': RGBA | RGB | HEX;
    '--color-base-white': RGBA | RGB | HEX;
}

export const colorsCssVariables: ColorsCssVariables = {
    // Default colors
    '--color-base-black': '#000000',
    '--color-base-white': '#FFFFFF',

    // Brand colors
    '--color-base-brand-black': '#000000',
    '--color-base-brand-grey-dark': '#595959',
    '--color-base-brand-grey-light': '#E3E3E3',
    '--color-base-brand-grey-lighter': '#EBEBEB',
    '--color-base-brand-grey-neutral': '#999999',
    '--color-base-brand-red': '#E31100',
    '--color-base-brand-white': '#FFFFFF',

    //Transparency colors
    '--color-base-black-75': 'rgba(18, 20, 19, 0.75)',
    '--color-base-black-50': 'rgba(18, 20, 19, 0.50)',
    '--color-base-black-25': 'rgba(18, 20, 19, 0.25)',
    '--color-base-black-10': 'rgba(18, 20, 19, 0.10)',

    '--color-base-grey-50': 'rgba(227, 227, 227, 0.5)',

    '--color-base-white-75': 'rgba(246, 245, 242, 0.75)',
    '--color-base-white-50': 'rgba(246, 245, 242, 0.50)',
    '--color-base-white-25': 'rgba(246, 245, 242, 0.25)',
    '--color-base-white-10': 'rgba(246, 245, 242, 0.10)',

    // Informational
    '--color-base-error': '#CC0000',
    '--color-base-success': '#2CBF4C',
};
