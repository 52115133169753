import defaultTheme from 'config/branding/themes/default';

export const themes = {
    default: defaultTheme,
};
export const validThemes = Object.keys(themes);

export const activateTheme = (themeName: string): void => {
    if (validThemes.indexOf(themeName) === -1) {
        return;
    }
    const theme = themes[themeName];
    const root = document.documentElement;
    Object.keys(theme).forEach(key => {
        const value = theme[key];
        root.style.setProperty(key, value);
    });
};

export default themes;
