import { colorsCssVariables } from 'config/branding/colors';
import { gridCssResponsiveVariables } from 'config/branding/grid';
import { BaseCssVariables, Theme, ThemeAndCoreVariables, ThemeCssVariables } from 'config/branding/interfaces/themes';
import { opacityCssVariables } from 'config/branding/opacity';
import { ratiosCssResponsiveVariables } from 'config/branding/ratios';
import { bannerCssVariables } from 'config/branding/themes/default//banner';
import { buttonsCssVariables } from 'config/branding/themes/default/buttons';
import { checkoutCssVariablesCore } from 'config/branding/themes/default/checkout';
import { coreCssVariables } from 'config/branding/themes/default/core';
import { footerCssVariables } from 'config/branding/themes/default/footer';
import { generalCssVariables } from 'config/branding/themes/default/general';
import { headerCssVariables } from 'config/branding/themes/default/header';
import { modulesCssVariables } from 'config/branding/themes/default/modules';
import { pdpCssVariables } from 'config/branding/themes/default/pdp';
import { transitionsCssResponsiveVariables } from 'config/branding/transitions';
import { zIndexCssResponsiveVariables } from 'config/branding/z-index';
import { colorsCssVariablesCore } from '@activebrands/core-web/config/branding/colors';
import { siteCssVariables } from '@activebrands/core-web/config/branding/site';
import { breakpoints } from '@activebrands/core-web/config/breakpoints';

const themeCssVariables: ThemeCssVariables = {
    ...footerCssVariables,
    ...modulesCssVariables,
    ...headerCssVariables,
    ...bannerCssVariables,
    ...pdpCssVariables,
    ...checkoutCssVariablesCore,
    ...buttonsCssVariables,
    ...generalCssVariables,
};

const baseCssVariables: BaseCssVariables = {
    ...colorsCssVariables,
    ...opacityCssVariables,
};

const staticVariables: ThemeAndCoreVariables = {
    ...coreCssVariables,
    ...colorsCssVariablesCore,
    ...themeCssVariables,
    ...baseCssVariables,
    ...siteCssVariables,
};

const defaultTheme: Theme = {
    statics: staticVariables,
    responsives: {
        'mobile.sm': {
            '--height-header': '56px',
            '--height-overlay': 'calc(100dvh - var(--height-header))',
            '--margin-bottom-archive-hero': '64px',
            '--margin-bottom-default-hero': '40px',
            '--margin-bottom-module': '64px',
            '--margin-content': '12px',
            '--margin-overlay': '4px', // 4px is for the gap around the overlay
            '--padding-image-pdp': '5%',
            '--padding-image-productCard': '10%',
            '--padding-content-shop-the-look': '0px',
            '--width-basket-overlay': '100vw',
            '--width-overlays': '100vw',
        },
        'mobile.md': {},
        'mobile.lg': {
            '--width-basket-overlay': '500px',
            '--width-overlays': '400px',
        },
        'tablet.sm': {
            '--margin-content': '12px',
        },
        'tablet.lg': {},
        'desktop.sm': {
            '--checkout-corridor-max-width': '1366px',
            '--margin-bottom-archive-hero': '80px',
            '--margin-bottom-default-hero': '64px',
            '--margin-bottom-module': '80px',
            '--margin-content': '24px',
            '--margin-overlay': '12px', // 12px is 4px gap around the overlay + 8px for scrollbar width
            '--padding-image-productCard': '5%',
        },
        'desktop.md': {
            '--padding-image-pdp': '10%',
        },
        'desktop.lg': {
            '--site-corridor-max-width': '3840px',
        },
    },
};

breakpoints.forEach(breakpoint => {
    defaultTheme.responsives[breakpoint.label] = {
        ...ratiosCssResponsiveVariables[breakpoint.label],
        ...gridCssResponsiveVariables[breakpoint.label],
        ...transitionsCssResponsiveVariables[breakpoint.label],
        ...zIndexCssResponsiveVariables[breakpoint.label],

        // Always write the theme variables last
        ...defaultTheme.responsives[breakpoint.label],
    };
});

export default defaultTheme;
