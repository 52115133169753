import { CssThemeVariable } from '@activebrands/core-web/types/css-types';

interface GeneralCssVariables {
    [key: CssThemeVariable]: CssThemeVariable | string;
}

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const generalCssVariables: GeneralCssVariables = {
    //SWEETPROTECTION NEW VARIABLES

    // ----------------------------//
    // Gradient
    // ----------------------------//

    '--color-bg-gradient': 'var(--color-base-white-10)',

    // Site selector
    // ----------------------------//

    '--color-text-site-selector': 'var(--color-base-brand-grey-light)',

    // Product card
    '--color-text-product-card': 'var(--color-base-black)',
    '--color-bg-quickshop-overlay': 'var(--color-base-white)',
    '--color-bg-quickshop-button': 'var(--color-base-black)',
    '--color-text-quickshop-overlay': 'var(--color-base-brand-grey-dark)',
    '--color-border-buttons-quickshop-overlay': 'var(--color-base-black)',
    '--color-bg-quickshop-button-disabled': 'var(--color-base-brand-grey-lighter)',

    // Product card mini
    '--color-bg-product-card-mini': 'transparent',
    '--color-text-subtle-product-card-mini': 'var(--color-base-brand-grey-dark)',
    '--color-text-product-card-mini': 'var(--color-base-black)',
    '--color-bg-quickshop-hover': 'var(--color-base-black-25)',
    '--color-icon-basket-card': 'var(--color-base-brand-grey-dark)',
    '--color-border-quickshop': 'var(--color-base-brand-grey-light)',
    '--color-border-product-card-mini': 'var(--color-base-brand-grey-light)',

    // Feature Card
    '--color-border-feature-card': 'var(--color-base-brand-grey-light)',

    // basket
    '--color-text-basket': 'var(--color-base-black)',
    '--color-text-basket-active-view': 'var(--color-base-brand-grey-dark)',
    '--color-border-basket': 'var(--color-base-brand-grey-light)',
    '--color-border-basket-active': 'var(--color-base-black)',

    // ----------------------------//
    // Background
    // ----------------------------//

    '--color-bg': 'var(--color-base-white)',
    '--color-bg-inverted': 'var(--color-base-black)',
    '--color-bg-list-bullet': 'var(--color-base-black)',

    // PLP
    '--color-text-hero': 'var(--color-base-black)',
    '--color-border-variant-image': 'var(--color-base-brand-grey-dark)',
    '--color-border-variant-image-active': 'var(--color-base-brand-black)',
    '--color-border-variant-image-hover': 'var(--color-base-brand-grey-dark)',
    '--color-bg-variant-image-out-of-stock': 'var(--color-base-brand-white)',

    // ----------------------------//
    // Images
    // ----------------------------//

    '--color-bg-image': 'var(--color-base-brand-grey-lighter)',

    // ----------------------------//
    // General Varaibles (Global)
    // ----------------------------//

    '--color-link': 'var(--color-base-black)',
    '--color-text': 'var(--color-base-black)',
    '--color-text-inverted': 'var(--color-base-white)',
    '--color-text-error': 'var(--color-base-error)',
    '--color-pattern-backdrop': 'var(--color-base-white-10)',

    // ----------------------------//
    // Navigation
    // ----------------------------//

    '--color-bg-navigation': 'var(--color-base-white)',
    '--color-text-navigation': 'var(--color-base-black)',
    '--width-navigation': '400px',

    // Filterbar
    '--color-text-filterbar': 'var(--color-base-black)',
    '--color-text-subtle-filterbar': 'var(--color-base-brand-grey-dark)',
    '--color-bg-filterbar': 'var(--color-base-white)',
    '--color-border-filterbar': 'var(--color-base-brand-grey-light)',
    '--color-border-apply-button': 'var(--color-base-black)',

    //Sortbar
    '--color-bg-sortbar': 'var(--color-base-white)',
    '--color-text-sortbar': 'var(--color-base-black)',
    '--color-text-subtle-sortbar': 'var(--color-base-brand-grey-light)',

    // Sticky filter bar
    '--color-bg-sticky-filter-bar': 'var(--color-base-black)',
    '--color-text-sticky-filter-bar': 'var(--color-base-white)',

    // ----------------------------//
    // Background borders
    // ----------------------------//

    '--color-border': 'var(--color-base-black)',
    '--color-border-inverted': 'var(--color-base-black)',
    '--color-border-article-card': 'var(--color-base-brand-grey-light)',
    '--color-border-overlay': 'var(--color-base-brand-grey-light)',

    // Radio input
    '--color-border-radio-button': 'var(--color-base-black)',
    '--color-border-radio-button-inverted': 'var(--color-base-white)',
    '--color-bg-radio-button': 'var(--color-base-black)',
    '--color-bg-radio-button-inverted': 'var(--color-base-white)',
    '--color-bg-inverted-radio-input': 'var(--color-base-black)',

    // Checkbox
    '--color-text-checkbox': 'var(--color-base-black)',
    '--color-bg-checkbox': 'var(--color-base-black)',
    '--color-border-checkbox-unchecked': 'var(--color-base-black)',
    '--color-border-checkbox-checked': 'var(--color-base-black)',
    '--color-border-checkbox-inverted': 'var(--color-base-white)',
    '--color-bg-checkbox-checked': 'var(--color-base-black)',
    '--color-text-subtle-checkbox': 'var(--color-base-brand-grey-dark)',
    '--color-bg-checkbox-inverted-checked': 'var(--color-base-white)',
    '--color-text-focus-checkbox-label': 'rgba(0,0,0,0.1)',

    // ----------------------------//
    // Wrong site pop up
    // ----------------------------//

    '--color-border-wrongsitepopup': 'var(--color-base-brand-grey-light)',

    // Pagination
    '--color-text-pagination': 'var(--color-base-black)',
    '--color-text-subtle-pagination': 'var(--color-base-brand-grey-dark)',
    '--color-bg-chosen-page-pagination': 'var(--color-base-black)',
    '--color-text-chosen-page-pagination': 'var(--color-base-white)',

    // Newsletter
    '--color-text-newsletter': 'var(--color-base-white)',

    // Account
    '--color-border-account': 'var(--color-base-brand-grey-light)',
    '--color-bg-account': 'var(--color-base-white)',
    '--color-bg-account-shortcuts': 'var(--color-base-white)',
    '--color-bg-account-subtle': 'var(--color-base-brand-grey-light)',
    '--color-border-account-shortcuts': 'var(--color-base-brand-grey-light)',
    '--color-text-account': 'var(--color-base-black)',
    '--color-border-menu-items': 'var(--color-base-black)',
    '--color-text-active': 'var(--color-base-brand-grey-dark)',

    // Act
    '--color-text-act': 'var(--color-base-white)',
    '--color-bg-act-voucher': 'var(--color-base-white)',
    '--color-text-subtle-act': 'var(--color-base-brand-grey-dark)',

    // ----------------------------//
    // Text
    // ---------------------------//

    '--color-text-subtle': 'var(--color-base-brand-grey-dark)',

    // ----------------------------//
    // Input
    // ---------------------------//

    '--color-border-input': 'var(--color-base-brand-grey-neutral)',
    '--color-text-input': 'var(--color-base-black)',
    '--color-bg-text-input': 'transparent',
    '--color-text-subtle-input': 'var(--color-base-brand-grey-dark)',

    // ----------------------------//
    // Change password overlay
    // ---------------------------//

    '--color-bg-password-overlay': 'var(--color-base-white)',

    // ----------------------------//
    // Favorite brands
    // ---------------------------//

    '--color-bg-favorite-brand': 'var(--color-base-white)',
    '--color-border-favorite-brand': 'var(--color-base-brand-grey-light)',

    // ----------------------------//
    // Added to basket
    // ---------------------------//

    '--color-border-added-to-basket': 'var(--color-base-brand-grey-light)',

    // ----------------------------//
    // Notification
    // ---------------------------//

    '--color-bg-notification-wrapper': 'var(--color-base-white)',
    '--color-border-notification-wrapper': 'var(--color-base-brand-grey-light)',

    // ---------------------------//
    // Article
    // ---------------------------//

    '--color-border-article-information': 'var(--color-base-brand-grey-light)',
    '--color-bg-article-information': 'var(--color-base-white)',
    '--color-text-article-subtle-heading': 'var(--color-base-brand-grey-dark)',

    // ----------------------------//
    // Beta Form
    // ---------------------------//

    '--color-text-beta-form': 'var(--color-base-black)',
    '--color-bg-beta-form': 'var(--color-base-white)',
    '--color-border-beta-form': 'var(--color-base-black-25)',

    // ----------------------------//
    // Search
    // ---------------------------//
    '--color-border-search-input': 'var(--color-base-brand-grey-light)',
    '--color-bg-search-tabs': 'var(--color-base-white)',
    '--color-border-search-tabs': 'var(--color-base-brand-grey-light)',
    '--color-border-link-search-tabs': 'transparent',
    '--color-border-link-search-tabs-highlight': 'var(--color-base-black)',
    '--color-text-link-search-tabs': 'var(--color-base-brand-grey-dark)',
    '--color-text-link-search-tabs-highlight': 'var(--color-base-black)',

    // ----------------------------//
    // Size guide overlay
    // ---------------------------//
    '--color-border-size-guide-overlay': 'var(--color-base-brand-grey-light)',

    //OLD VARIABLES
    //TODO: Remove these when all components are updated to use the new variables

    // ----------------------------//
    // Text
    // ---------------------------//

    '--color-text-accent': 'var(--color-base-error)',
    '--color-text-disabled': 'var(--color-base-temp-blue-3)',

    // ----------------------------//
    // Core
    // ---------------------------//

    // Backdrop
    '--color-bg-backdrop': 'var(--color-base-black-25)',
    '--color-bg-backdrop-site-selector': 'var(--color-base-black-50)',
    '--top-content-backdrop': 'var(--dynamic-main-content-top)',
    '--transition-duration-content-backdrop': 'var(--duration-fast)',
    '--transition-timing-function-content-backdrop': 'var(--ease)',
    '--zindex-content-overlay-backdrop': 'var(--zindex-overlay)',

    // ----------------------------//
    // Background
    // ----------------------------//

    '--color-bg-accent': 'var(--color-base-temp-blue-4)',
    '--color-bg-error': 'var(--color-base-temp-blue-6)',
    '--color-bg-highlight': 'var(--color-base-temp-blue-2)',
    '--color-bg-secondary': 'var(--color-base-temp-blue-2)',
    '--color-bg-contrast': 'var(--color-base-temp-blue-2)',
    '--color-bg-active-navigation': 'var(--color-base-temp-blue-9)',

    // ----------------------------//
    // Background borders
    // ----------------------------//

    '--color-border-subtle': 'var(--color-base-temp-blue-9)',
    '--color-border-accent': 'var(--color-base-error)',
    '--color-border-error': 'var(--color-base-error)',

    // ----------------------------//
    // Contrast
    // ----------------------------//

    '--color-contrast-lowest': 'var(--color-base-temp-blue-2)',
    '--color-contrast-lower': 'var(--color-base-temp-blue-9)',
    '--color-contrast-low': 'var(--color-base-temp-blue-9)',
    '--color-contrast-medium': 'var(--color-base-temp-blue-7)',
    '--color-contrast-high': 'var(--color-base-temp-blue-9)',
    '--color-contrast-higher': 'var(--color-base-temp-blue-9)',
    '--color-contrast-dark': 'var(--color-base-temp-blue-10)',

    // ----------------------------//
    // Links
    // ----------------------------//

    '--link-color': 'var(--color-base-temp-blue-5)',

    // ----------------------------//
    // Swatches
    // ----------------------------//

    '--color-swatches-border-color': 'var(--color-base-brand-grey-light)',
    '--color-swatches-border-color-highlight': 'var(--color-base-black)',
    '--color-swatches-border-color-highlight-inner': 'var(--color-base-white)',

    // ----------------------------//
    // Shadow
    // ----------------------------//

    '--color-shadow': 'var(--color-base-temp-blue-7)',
    '--color-shadow-inverted': 'var(--color-base-temp-blue-1)',
    '--shadow-drop': '--color-base-temp-blue-9)',

    // ACT colors
    '--color-text-voucher-subtle': 'var(--color-base-temp-blue-1)',

    // ----------------------------//
    // Header Overlays
    // ----------------------------//

    '--color-bg-header-overlay': 'var(--color-base-white)',

    // ----------------------------//
    // Banner
    // ----------------------------//

    // Primary
    '--color-bg-banner-brand-primary': 'var(--color-base-temp-blue-9)',
    '--color-text-banner-brand-primary': 'var(--color-base-temp-blue-1)',

    // Secondary
    '--color-bg-banner-brand-secondary': 'var(--color-base-temp-blue-7)',
    '--color-text-banner-brand-secondary': 'var(--color-base-temp-blue-7)',

    // Accent
    '--color-bg-banner-accent': 'var(--color-base-temp-blue-5)',
    '--color-text-banner-accent': 'var(--color-base-temp-blue-1)',

    // Attention
    '--color-bg-banner-attention': 'var(--color-base-error)',
    '--color-text-banner-attention': 'var(--color-base-temp-blue-1)',

    // ----------------------------//
    // Badges
    // ----------------------------//

    // Default
    '--color-bg-badge': 'transparent',
    '--color-text-badge': 'var(--color-base-black)',

    // Brand primary
    '--color-bg-badge-brand-primary': 'transparent',
    '--color-text-badge-brand-primary': 'var(--color-base-black)',

    // Accent
    '--color-bg-badge-accent': 'transparent',
    '--color-text-badge-accent': 'var(--color-base-error)',

    // ----------------------------//
    // Tags
    // ----------------------------//

    '--color-bg-article-tag': 'var(--color-base-temp-blue-9)',
    '--color-text-article-tag': 'var(--color-base-temp-blue-1)',
    '--color-bg-order-tag': 'var(--color-base-temp-blue-5)',

    // ----------------------------//
    // Preview
    // ----------------------------//

    '--color-bg-preview': 'var(--color-base-temp-blue-7)',
    '--color-bg-preview-button': 'var(--color-base-temp-blue-7)',

    // Category buttons
    '--color-text-category-button': 'var(--color-base-temp-blue-9)',
    '--color-bg-category-button': 'var(--color-base-temp-blue-9)',
    '--color-text-category-button-active': 'var(--color-base-temp-blue-1)',
    '--color-bg-category-button-active': 'var(--color-base-temp-blue-9)',
    '--color-text-category-button-hover': 'var(--color-base-temp-blue-1)',
    '--color-bg-category-button-hover': 'var(--color-base-temp-blue-9)',
    '--color-border-button': 'var(--color-base-temp-blue-1)',

    // Breadcrumbs
    '--color-text-breadcrumb': 'var(--color-base-temp-blue-5)',
    '--color-text-breadcrumb-active': 'var(--color-base-temp-blue-1)',

    // Media Gallery
    '--color-bg-media-gallery-icon': 'var(--color-base-temp-blue-1)',
};
