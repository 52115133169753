import { useState } from 'react';
import AnimatedOverlay from '@activebrands/core-web/components/overlays/AnimatedOverlay';
import media from '@activebrands/core-web/config/media';
import Events from '@activebrands/core-web/libs/Events';
import useEvent from '@activebrands/core-web/libs/Events/useEvent';
import { useStyletron } from '@activebrands/core-web/libs/styletron';

const NotificationsOverlay = () => {
    const [notifications, setNotifications] = useState([]);
    const [css] = useStyletron();

    const wrapperClass = css({
        position: 'fixed',
        bottom: 'calc(var(--height-header) + 1px)',
        width: '100%',
        zIndex: 'var(--zindex-notifications)',

        [media.min['desktop.sm']]: {
            top: 'var(--height-header)',
            right: 0,
            bottom: 'auto',
            width: 'auto',
        },
    });

    useEvent('OVERLAY.STATE_CHANGE', ({ type, ids, props: data }) => {
        if (type === 'open' && ids[0].indexOf('notification-') === 0) {
            setNotifications(prev => [...prev, ids[0]]);

            setTimeout(() => {
                Events.trigger(`OVERLAY.OPEN_${ids[0]}`, { ...data.props, component: data.component });
            }, 0);
        }
    });

    if (!notifications.length) {
        return null;
    }

    return (
        <div className={wrapperClass}>
            {notifications.map(key => (
                <AnimatedOverlay $style={{ position: 'static' }} animation="slideRTL" id={key} key={key} />
            ))}
        </div>
    );
};

export default NotificationsOverlay;
