import { CoreCssVariables } from '@activebrands/core-web/types/components/index';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const coreCssVariables: CoreCssVariables = {
    // Backdrop
    '--color-bg-backdrop': 'var(--color-base-black-25)',
    '--top-content-backdrop': 'var(--dynamic-main-content-top)',
    '--transition-duration-content-backdrop': 'var(--duration-fast)',
    '--transition-timing-function-content-backdrop': 'var(--ease)',
    '--zindex-content-overlay-backdrop': 'var(--zindex-overlay)',

    // Breadcrumbs
    '--color-text-breadcrumbs-color-default': 'var(--color-base-black)',
    '--color-text-breadcrumbs-color-active': 'var(--color-base-brand-grey-dark)',
    '--padding-content-breadcrumbs': 'var(--margin-content)',
    '--color-link-slash-default': 'var(--color-base-black)',

    // Form Information
    '--color-bg-form-information': 'var(--color-base-white)',
    '--color-border-form-information-error': 'var(--color-base-error)',
    '--color-border-form-information-success': 'var(--color-base-black)',
    '--color-icon-form-information-error': 'var(--color-base-error)',
    '--color-icon-form-information-success': 'var(--color-base-success)',
    '--color-text-form-information': 'var(--color-base-black)',

    // Price
    '--color-price-active-original': 'var(--color-base-black)',
    '--color-text-price-original-active': 'var(--color-base-error)',
    '--color-text-price-sale-active': 'var(--color-base-brand-grey-dark)',

    // Tooltip
    '--tooltip-content-background-color': 'var(--color-base-white)',
    '--tooltip-content-border-color': 'var(--color-base-brand-grey-light)',

    // RichText
    '--color-link-rich-text': 'var(--color-base-brand-grey-dark)',
    '--color-link-rich-text-hover': 'var(--color-base-brand-grey-neutral)',
};
